import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const Bogucianka = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Bogucianka",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #22967c, #30af93, #2ABA9A, #30af93, #22967c )",
        icons: "#2ABA9A",
        navClass: "bogucianka",
        ogImage: require("../../assets/img/portfolio/bogucianka_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/bogucianka/",
      }}
    >
      <PortfolioHeader name="bogucianka" height="176" />
      <section className="container-fluid bogucianka_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Osiedle Bogucianka</h1>
              <ul>
                <li>Logo</li>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Deweloper należy do grupy Genus Grupa Deweloperska, która w
                swoim portfolio ma realizację następujących obiektów: Zacisze 4,
                Kurniki 9, Przemiarki 14, Józefińska 31, Siemiradzkiego 25 oraz
                Dom nad Młynówką w Krakowie, a także zespół domów
                jednorodzinnych w zabudowie bliźniaczej w Januszowicach, Pałac w
                Cianowicach T. Talowskiego, oraz aktualną realizację Grupy tj.
                Osiedle Bogucianka.
              </p>
              <p>
                Nasza współpraca obejmuje wsparcie działań sprzedażowych
                inwestycji Osiedle Bogucianka. Zakres współpracy obejmuje
                opracowanie modelu funkcjonalnego serwisu internetowego wraz z
                wykonaniem projektu oraz wdrożenie i prowadzenie kampanii
                leadowej, którą wspieraliśmy przez aktywne prowadzenie fanpage'a
                inwestycji.
              </p>
              <p>Cel:</p>
              <ol>
                <li>
                  Stworzyć narzędzia do prezentacji oferty (serwis internetowy,
                  logo)
                </li>
                <li>
                  Zapewnić wsparcie sprzedaży - kampania leadowa, banery
                  reklamowe
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bogucianka_section_25">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/bogucianka_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/bogucianka_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>74</span>
                  <span>50</span>
                  <span>60</span>
                  <span>33</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>71</span>
                  <span>65</span>
                  <span>64</span>
                  <span>68</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>63</span>
                  <span>86</span>
                  <span>81</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>40</span>
                  <span>40</span>
                  <span>40</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>cbb076</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bogucianka_section_3">
        <div className="row row_1">
          <div className="col-md-6">
            <div className="inner">
              <h3>Serwis internetowy</h3>
              <p>
                Strona internetowa www.bogucianka.pl została zrealizowana dla
                dewelopera działającego na rynku deweloperskim. Najważniejszym
                zadaniem, jakiemu mieliśmy sprostać przy projekcie strony było
                stworzenie unikalnych widoków graficznych, które pozwolą pokazać
                inwestycję o wysokim standardzie, położoną w regionie Tyńca,
                czyli tzw. zielonych płuc Krakowa, a zarazem regionu niezwykle
                atrakcyjnego dla wszystkich ceniących sobie spokój i aktywność
                fizyczną.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/bogucianka_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters align-items-center row_2">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/bogucianka_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-7">
            <div className="inner">
              <p>
                Chcieliśmy także skupić się na przedstawieniu unikatowej
                wartości dodanej dla tej inwestycji, jaką niewątpliwie jest 30
                arów terenu rekreacyjno-wypoczynkowegow obrębie osiedla, który
                jest przeznaczony wyłącznie dla mieszkańców.
              </p>
              <p>
                Projekt layoutu graficznego zdeterminowany był także
                maksymalizacją użyteczności strony oraz celów biznesowych
                Klienta. Najważniejszą funkcją strony było pozyskiwanie leadów
                od potencjalnych klientów.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-7 offset-md-2">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/bogucianka_main_4.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-end row_4">
          <div className="col-md-2">
            <img
              className="img-fluid mobile"
              src={require("../../assets/img/portfolio/bogucianka_main_6.png")}
              alt=""
            />
          </div>
          <div className="col-md-9 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/bogucianka_main_5.png")}
              alt=""
            />
            <p>
              Oprócz projektu strony zajęliśmy się także zakodowaniem jej do w
              pełni działającej strony internetowej, do której zostały wpięte
              niezbędne narzędzia analityczne – m.in. Google Analytics czy
              Google Tag Manager. To umożliwiło śledzenie źródeł zapytań
              ofertowych zwiększając przy tym efektywność prowadzonej przez WHEN
              kampanii marketingowej.
            </p>
          </div>
        </div>
        <div className="row align-items-center row_5">
          <div className="col-md-6">
            <div className="inner">
              <h3>Kampania leadowa</h3>
              <p>
                Pierwszy miesiąc kampanii potraktowaliśmy jako testowy –
                analizowaliśmy dane obrazujące zachowania użytkowników,
                realizację ustalonych celów i poziom konwersji. Prowadziliśmy
                rozbudowaną kampanię w sieci wyszukiwania i reklamowej Google w
                regionie małopolski, aby dotrzeć do osób, które mogą być
                zainteresowane kupnem domu pod Krakowem. Dobraliśmy odpowiednie
                słowa kluczowe związane z rynkiem nieruchomości oraz portale
                tematyczne, na których pojawiały się kreacje graficzne. Nasze
                kampanie śledziły również przesłania formularzy kontaktowych na
                stronie www. Równolegle prowadziliśmy fanpage inwestycji oraz
                rozbudowane kampanie na Facebooku.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/bogucianka_main_7.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_6 justify-content-center">
          <div className="col-12">
            <img
              className="img-fluid baner_img"
              src={require("../../assets/img/portfolio/bogucianka_baner.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Bogucianka;
